<script setup lang="ts">
import type { SbGridStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbGridStoryblok }>()

const gridWidth = 12

const contentWidth = computed(() => blok.columns.reduce((acc, column) => acc + Number(column.cols), 0))

const overflowIdx = computed(() => {
  if (contentWidth.value > gridWidth) {
    return contentWidth.value / blok.columns.length - 1
  }

  return 0
})

const gridColStart = computed(() => {
  if (overflowIdx.value === 0) {
    return Math.floor(((gridWidth - contentWidth.value) / 2) + 1)
  }

  const overflowCols = blok.columns.length - (overflowIdx.value || 0 + 1)

  const overflowColsWidth = blok.columns
    .slice(0, overflowCols)
    .reduce((acc, column) => acc + Number(column.cols), 0)

  return Math.floor(((gridWidth - overflowColsWidth) / 2) + 1)
})

const gridColumnStartClass = computed(() => {
  switch (Number(gridColStart.value)) {
    case 1:
      return 'lg:col-start-1'
    case 2:
      return 'lg:col-start-2'
    case 3:
      return 'lg:col-start-3'
    case 4:
      return 'lg:col-start-4'
    case 5:
      return 'lg:col-start-5'
    case 6:
      return 'lg:col-start-6'
    case 7:
      return 'lg:col-start-7'
    case 8:
      return 'lg:col-start-8'
    case 9:
      return 'lg:col-start-9'
    case 10:
      return 'lg:col-start-10'
    case 11:
      return 'lg:col-start-11'
    case 12:
      return 'lg:col-start-12'

    default:
      return ''
  }
})
</script>

<template>
  <div
    v-editable="blok"
    class="isolate grid grid-cols-1 lg:grid-cols-12"
    :class="{
      'items-center': blok.isVerticallyCentered,
    }"
    :style="{
      columnGap: blok.columnsGap && `${Number(blok.columnsGap) * 4}px`,
      rowGap: blok.rowGap && `${Number(blok.rowGap) * 4}px`,
    }"
  >
    <StoryblokComponent
      v-for="(columnBlok, index) in blok.columns"
      :key="columnBlok._uid"
      :blok="columnBlok"
      :class="`${blok.isCentered && index === overflowIdx ? gridColumnStartClass : 'lg:col-start-auto'}`"
    >
      {{ columnBlok.cols }}
    </StoryblokComponent>
  </div>
</template>
